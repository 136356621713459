import React from 'react';
import { Layout } from 'layouts';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import { Card, Grid } from '@material-ui/core';
import getStaticImageURL from '../shared/getStaticImageURL';
import ReactPlayer from 'react-player';
import ReviewCard from '../components/Review/ReviewCard';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dynamicHeight: {
    '@media(max-width:480px)': {
      height: '900px !important',
    },
  },
  tickerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '25px',
  },
  tickers: {
    color: '#00aec8 !important',
    fontSize: '80px !important',
    fontWeight: '900 !important',
    padding: '10px !important',
  },
  irmInfoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '15px',
  },
  testimonialVideoTitle: {
    color: '#151515',
    fontSize: '35px',
    fontWeight: '700',
    marginTop: '35px',
    marginBottom: '30px',
    marginLeft: '15px',
    marginRight: '15px',
  },
}));

const AboutDIPS = ({ ...props }) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { pageContext } = props;
  const data = pageContext.pageData ? pageContext.pageData : null;
  if (!data) {
    return;
  }
  const programLink = pageContext.programUrl;
  const { pageHeader, headerLogo, headerLogoScroll, screenName } =
    (data || {}).node || {};
  const headerLinks = getHeaderLInks('', programLink, data.noLinks);
  const logos = pageContext.logos || [];
  const footerLogos = pageContext.footerLogos || [];
  const helmetLink = `${Constants.appUrl}/about-dips`;

  const aboutDIPSPageInformation =
    t('aboutDIPS', {
      returnObjects: true,
    }) || [];

  const getPageDetails = () => {
    if (!ready) {
      return 'Loading...';
    } else {
      return aboutDIPSPageInformation;
    }
  };

  return (
    <Layout
      programLink={programLink}
      title={getPageDetails()?.title}
      content={[getPageDetails()?.subtitle]}
      headerLinks={headerLinks}
      logos={logos}
      footerLogos={footerLogos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      imageName={'about_banner.jpg'}
      contentAlignmentStyle={'textCenter'}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'aboutDips' }}
      screenName={screenName}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('aboutDips')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <div>
        <section id="program" className="invest-details">
          <div className="container">
            <GridContainer className="countries-contanier about-grid-cont">
              <GridItem md={12} sm={12} className="about-second-panel">
                {getPageDetails()?.introduction?.map((ele, index) => (
                  <p key={index}>{ele}</p>
                ))}
              </GridItem>
            </GridContainer>
          </div>
        </section>

        <section
          id="flyer"
          className="flyer-details"
          style={{ paddingBottom: '0px' }}
        >
          <div className="container">
            <GridContainer className="flyer-container">
              <GridItem sm={4} className="left-section">
                <h3 style={{ marginLeft: '25px', marginRight: '25px' }}>
                  {getPageDetails()?.features?.title}
                </h3>
                <img src={getStaticImageURL('logo-dips.png')} alt="DIPS"></img>
              </GridItem>
              <GridItem sm={8} className="right-section">
                {getPageDetails()?.features?.pointers.map((ele, index) => {
                  return (
                    <GridContainer
                      key={index}
                      className={
                        index + 1 == getPageDetails()?.features?.pointers.length
                          ? 'last right-section-row'
                          : 'right-section-row'
                      }
                    >
                      <GridItem className="item-logo" sm={3}>
                        <img
                          src={getStaticImageURL(
                            `vectoriel-${(index % 5) + 1}.png`
                          )}
                          alt=""
                        ></img>
                      </GridItem>
                      <GridItem className="item-desc" sm={9}>
                        <p>{ele}</p>
                      </GridItem>
                    </GridContainer>
                  );
                })}
              </GridItem>
            </GridContainer>
          </div>
        </section>

        <section id="program" className="invest-details">
          <div className="container">
            <GridContainer className="countries-contanier about-grid-cont">
              <GridItem md={12} sm={12} className="about-second-panel">
                <p>{getPageDetails()?.features?.content}</p>
              </GridItem>
              <GridItem md={12} sm={12} className="about-second-panel">
                <p>{getPageDetails()?.features?.tickers?.title}</p>
              </GridItem>
            </GridContainer>
            <GridContainer className="countries-contanier about-grid-cont">
              {getPageDetails()?.features?.tickers?.values?.map(
                (ele, index) => {
                  return (
                    <GridItem
                      md={6}
                      sm={12}
                      className={`about-second-panel ${classes.tickerContainer}`}
                      key={index}
                    >
                      <p className={classes.tickers}>{ele.count}</p>
                      <p>{ele.title}</p>
                    </GridItem>
                  );
                }
              )}
            </GridContainer>
          </div>
        </section>

        <section className="countries-intervenstion">
          <img
            className={[
              `countries-intervenstion-image ${classes.dynamicHeight}`,
            ]}
            src={getStaticImageURL('invention.jpg')}
            alt="countries-intervenstion"
            style={{ height: '500px' }}
          ></img>
          <div className="country-map">
            <h3 style={{ margin: '15px' }}>
              {getPageDetails()?.learnMore?.title}
            </h3>

            <div
              className="container about-project-container"
              style={{ marginTop: '75px' }}
            >
              <ReactPlayer
                width="100%"
                height="500px"
                url={getPageDetails()?.learnMore?.link}
                controls={true}
                style={{ borderRadius: '5px' }}
              />
            </div>
          </div>
        </section>

        <section id="project" className="project-details">
          <img
            className="project-details-image"
            src={getStaticImageURL('about_project_image.jpg')}
            alt="project-details"
            style={{ height: '700px' }}
          ></img>
          <div className="container about-project-container">
            <h3 style={{ marginLeft: '15px', marginRight: '15px' }}>
              {getPageDetails()?.irm?.title}
            </h3>
            <Card className="project-card">
              <GridContainer>
                <GridItem md={12} sm={12} className="about-second-panel">
                  {getPageDetails()?.irm?.content?.map((ele, index) => (
                    <p key={index}>{ele}</p>
                  ))}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={12} sm={12} className="about-second-panel">
                  <div className={classes.irmInfoContainer}>
                    <img src={getStaticImageURL('IRM.png')} alt="DIPS"></img>
                    <p style={{ fontSize: '12px', fontStyle: 'italic' }}>
                      {getPageDetails()?.irm?.citation}
                    </p>
                  </div>
                </GridItem>
              </GridContainer>
            </Card>
          </div>
        </section>

        <section id="project" className="project-details">
          <img
            className="project-details-image"
            src={getStaticImageURL('about_project_image.jpg')}
            alt="project-details"
          ></img>
          <div className="container about-project-container">
            <h3 style={{ marginLeft: '15px', marginRight: '15px' }}>
              {t('testimonials')}
            </h3>
            <div>
              <GridContainer spacing={1} style={{ padding: '12px' }}>
                {getPageDetails()?.testimonials?.person?.map((ele, index) => (
                  <GridItem
                    item
                    key={index}
                    md={6}
                    sm={12}
                    xs={12}
                    className="about-second-panel"
                  >
                    {index !== 2 && <ReviewCard reviewItem={ele} />}
                  </GridItem>
                ))}

                {getPageDetails()?.testimonials?.person?.map((ele, index) => (
                  <div
                    key={index}
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      paddingInline: '5px',
                    }}
                  >
                    <Grid
                      className="about-second-panel"
                      item
                      xs={12}
                      sm={12}
                      lg={6}
                      container
                    >
                      {index == 2 && <ReviewCard reviewItem={ele} />}
                    </Grid>
                  </div>
                ))}
              </GridContainer>
            </div>

            <p className={classes.testimonialVideoTitle}>
              {getPageDetails()?.testimonials?.video?.title}
            </p>
            <ReactPlayer
              width="100%"
              height="500px"
              url={getPageDetails()?.testimonials?.video?.link}
              controls={true}
              style={{ borderRadius: '5px' }}
            />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutDIPS;
