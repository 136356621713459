import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import getStaticImageURL from '../../shared/getStaticImageURL';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '400px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)',
    padding: '25px',
  },
  containerPrimary: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '10px',
    '@media(max-width:480px)': {
      flexDirection: 'column',
    },
  },
  contentPrimaryText: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '10px',
    lineHeight: '30px',
    color: '#000',
    textAlign: 'center',
    margin: '15px',
  },
  contentDescText: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '10px',
    lineHeight: '30px',
    color: '#868686',
    textAlign: 'left',
  },
}));

const ReviewCard = ({ reviewItem }) => {
  const classes = useStyles();

  return (
    <Paper component="form" className={classes.root}>
      <div>
        <div className={classes.containerPrimary}>
          <img
            style={{ borderRadius: '50%', width: '150px', marginBottom: '0px' }}
            src={getStaticImageURL(reviewItem?.image)}
            alt="Review"
          ></img>
          <div className={classes.contentPrimaryText}>{reviewItem?.title}</div>
        </div>
        <div>
          <div className={classes.contentDescText}>{reviewItem?.content}</div>
        </div>
      </div>
    </Paper>
  );
};

export default ReviewCard;
